import React from 'react';
import { Card, Button, Image, InputGroup, FormControl } from 'react-bootstrap'
import {
    Link
} from "react-router-dom";
import { StickyContainer, Sticky } from 'react-sticky';
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from './AcademyNavbar'

function Program() {
    return (
        <div>
            <AcademyNavbar />
            <div className="container-fluid pricing-bar py-3">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-6">
                            <p className="font-weight-bold mb-0">
                                <span className="d-block pt-2 small mb-0">2/6 FREE ARTICLES LEFT </span>
                            </p>
                        </div>
                        <div className="col-6 text-right">
                            <p className="font-weight-bold mb-0">
                                <span className="mr-2 d-none d-md-inline small">Why Subscribe?</span>
                                <Button variant="primary d-inline font-weight-bold mb-0" size="sm" style={{ marginTop: '-5px' }}>Subscribe</Button>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <div className="container-fluid bg-black text-white pb-5 post-content pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col text-white py-5">
                                <h1 className="font-weight-light">Future of work is here.</h1>
                            </div>
                        </div>
                        <div className="row mb-3 consult-search">
                            <div className="col-md-12">
                                <div className="col-md-12 bg-white pt-3 pb-1 rounded">
                                <InputGroup className="mb-3">
                                    <FormControl placeholder="FIELD" className="border-0" />
                                    <FormControl placeholder="LOCATION" className="border-top-0 border-bottom-0" />
                                    <InputGroup.Append>
                                        <Button className="rounded">Search</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5  program-content-web" style={{ background: "#EFEFEF" }}>
                    <div className="container">
                        <div className="row pb-3">
                            <div className="col-md-4">
                                <h4>You might also be interested in</h4>
                            </div>
                        </div>
                        <div className="row pb-5">
                            <div className="col-md-3">
                                <Card className="bg-transparent link-card border-0">
                                    <div className="meta-image rounded mb-2" style={{ height: "180px", backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe)" }}></div>
                                    <Card.Body className="p-0">
                                        <Card.Text>
                                            <p className="url text-uppercase">IGESIA.CO</p>
                                            <p className="title">Innovation Management: Strategy and Execution</p>
                                            <p className="meta-description">Leverage the innovative potential of your organization</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-md-3">
                                <Card className="bg-transparent link-card border-0">
                                    <div className="meta-image rounded mb-2" style={{ height: "180px", backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe)" }}></div>
                                    <Card.Body className="p-0">
                                        <Card.Text>
                                            <p className="url text-uppercase">IGESIA.CO</p>
                                            <p className="title">Innovation Management: Strategy and Execution</p>
                                            <p className="meta-description">Leverage the innovative potential of your organization</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-md-3">
                                <Card className="bg-transparent link-card border-0">
                                    <div className="meta-image rounded mb-2" style={{ height: "180px", backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe)" }}></div>
                                    <Card.Body className="p-0">
                                        <Card.Text>
                                            <p className="url text-uppercase">IGESIA.CO</p>
                                            <p className="title">Innovation Management: Strategy and Execution</p>
                                            <p className="meta-description">Leverage the innovative potential of your organization</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-md-3">
                                <Card className="bg-transparent link-card border-0">
                                    <div className="meta-image rounded mb-2" style={{ height: "180px", backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe)" }}></div>
                                    <Card.Body className="p-0">
                                        <Card.Text>
                                            <p className="url text-uppercase">IGESIA.CO</p>
                                            <p className="title">Innovation Management: Strategy and Execution</p>
                                            <p className="meta-description">Leverage the innovative potential of your organization</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className="row pb-3 mx-1">
                            <div className="col border-top">
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col-md-4">
                                <h4>Explore other topics</h4>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Managing People
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Managing Self
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Leadership
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Strategy
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Finance
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Public Policy
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Industry 4.0
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Social Impact
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Communication
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Innovation
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Technology
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Entrepreneurship
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Marketing
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Career Planning
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Organizational Culture
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Decision Making
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Productivity
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                >Work-Life Balance
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default Program;