import React from 'react';
import { Card, Popover, OverlayTrigger, Button, Nav, NavDropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
    Link, useHistory
} from "react-router-dom";
import AcademyNavbar from './AcademyNavbar'

const categoryoptions = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Cateogry</Popover.Title>
        <Popover.Content>
            And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
);

const Category = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Category</Button>
    </OverlayTrigger>
);

const Dates = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Dates</Button>
    </OverlayTrigger>
);

function Academy() {

    const handleChange = function (event) {
        console.log(event.target.value)
    }

    const history = useHistory();

    function handleClick(path) {

        history.push(path);
    }

    return (
        <>
            <AcademyNavbar />
            <div className="container-fluid bg-black pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col text-white py-4">
                            <h1 className="font-weight-light">Everything you need to be future ready</h1>
                        </div>
                    </div>
                    <div className="row mb-5 text-white header">

                        <div className="col-md-4" onClick={() => handleClick('/programs')} style={{ cursor: "pointer" }}>
                            <div className="featured-image"
                                style={{
                                    backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Flarge_DH8mwmZn6VS2MyqBRzug3gHIF6YMfNa4za0MxeZHpyo.jpg?alt=media&token=c421c5d3-3eae-445a-86ff-85153f99428a)",
                                    backgroundPosition: "center center",
                                }}>
                            </div>
                            <h4 className="mt-2 mb-2">Executive Programs</h4>
                            <p className="small" style={{ color: "#CCC" }}>Live virtual learning with faculty from top schools across the globe with excellent networking opportunities</p>
                        </div>
                        <div className="col-md-4">
                            <div className="featured-image"
                                style={{
                                    backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Flarge_55u78LugE1_tq0aLB3UF2F8KLF_sG-pD6urviQs5oMQ.jpg?alt=media&token=aad3f0ef-86b3-411f-a3a9-8284ceb763df)",
                                    backgroundPosition: "center center"
                                }}>
                            </div>
                            <h4 className="mt-2">Knowledge</h4>
                            <p className="small" style={{ color: "#CCC" }}>Get curated list of articles, podcasts and videos by domain experts to stay on top of your industry</p>
                        </div>
                        <div className="col-md-4" onClick={() => handleClick('/consult')} style={{ cursor: "pointer" }}>
                            <div
                                className="featured-image"
                                style={{
                                    backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Flarge_gWYBzrU3cUzwIoCg6vN-psmrO4Q-AnOPAL4iJY58_Rw.jpg?alt=media&token=c50c14ee-3090-4e7f-bda5-1e3626651f0b)",
                                    backgroundPosition: "center center"
                                }}>
                            </div>
                            <h4 className="mt-2">Consult Experts</h4>
                            <p className="small" style={{ color: "#CCC" }}>Get professional advice from top faculty and executives across the globe</p>
                        </div>
                        {/* <div className="col-12 mt-5">
                            <div className="col-12 border-bottom"></div>
                        </div> */}

                    </div>
                </div>
            </div>
            <div className="container-fluid bg-light py-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col">
                            <h3 className="text-black" style={{ fontWeight: 400 }}>Innovation</h3>
                        </div>
                        <div className="col text-right pr-2">
                            <span className="text-black" className="p-1" style={{ fontWeight: 400 }}>Next <FontAwesomeIcon icon={faChevronRight} size="sm" /> </span>
                        </div>
                    </div>

                    <div className="row text-dark category-block">
                        <div className="col-md-8">
                            <div className="row" style={{cursor: "pointer"}} onClick={() => handleClick('/program')}>
                                <div className="col">
                                    <div className="pb-4">
                                        <div className="row">
                                            <div className="col-md-6 mb-2">
                                                <div className="featured-image" style={{
                                                    backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Flarge_sOVMLTyzbCT0efsBqZOALoBXn82LDFgxYIA1ZHwlEBo.jpg?alt=media&token=e26fb268-c1b3-44b2-a30c-72fc7b6aabee)",
                                                }}
                                                ></div>
                                            </div>
                                            <div className="col-md-6">
                                                <h3>Innovation Management: Strategy and Execution</h3>
                                                <p className="small">Gain the knowledge and confidence to successfully leverage the innovative potential of your organization</p>
                                                <span class="bg-lighter p-1 small rounded">Executive Program</span>
                                                <p className="small mb-0 mt-3"><FontAwesomeIcon icon={faAward} size="1x" style={{ color: "orange" }} /> Attested with a Record of Participation from the<br /> <strong>University of California, Los Angeles (UCLA) Extension</strong></p>
                                                <img width="150"
                                                    src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=b58a0dd8-71db-4489-b8bf-29293a09c252"
                                                />
                                            </div>
                                            <div className="col-12 mt-3 d-none d-md-block">
                                                <div className="col-12 border-bottom"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row"  style={{cursor: "pointer"}} onClick={() => handleClick('/program')}>
                                <div className="col">
                                    <div className="pb-4">
                                        <div className="row">
                                            <div className="col-md-6 mb-2">
                                                <div className="featured-image" style={{
                                                    backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe)",
                                                }}
                                                ></div>
                                            </div>
                                            <div className="col-md-6">
                                                <h3>Leading Digital Transformation and Innovation</h3>
                                                <p className="small">Gain the knowledge and confidence to successfully leverage the power of digital technologies for competitive advantage</p>
                                                <span class="bg-lighter p-1 small rounded">Executive Program</span>
                                                <p className="small mb-0 mt-3"><FontAwesomeIcon icon={faAward} size="1x" style={{ color: "orange" }} /> Attested with a Record of Participation from the<br /> <strong>University of California, Los Angeles (UCLA Extension)</strong></p>
                                                <img width="150"
                                                    src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=b58a0dd8-71db-4489-b8bf-29293a09c252"
                                                />
                                            </div>
                                            <div className="col-12 mt-3 d-block d-md-none">
                                                <div className="col-12 border-bottom"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 border-left">
                        <div className="row">
                            <div className="col mb-1">
                                <h5 className="mb-0 font-weight-bolder">From the Library</h5>
                                <p className="small">Curated resources from around world</p>
                            </div>
                        </div>
                            <div className="row" onClick={() => handleClick('/post')}>
                                <div className="col mb-3">
                                    <div className="border-bottom pb-3">
                                        <h5 className="mb-1">Innovation Isn’t an Idea Problem</h5>
                                        <p className="mt-1 mb-1 font-weight-bold author text-lighter">Stephen King</p>
                                        <span class="bg-lighter p-1 small rounded">Collection</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row" onClick={() => handleClick('/post')}>
                                <div className="col mb-3">
                                    <div className="border-bottom pb-3">
                                        <h5 className="mb-1">A Post-Pandemic Strategy for U.S. Higher Ed</h5>
                                        <p className="mt-1 mb-1 font-weight-bold author text-lighter">David Baldacci</p>
                                        <span class="bg-lighter p-1 small rounded">Article</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row" onClick={() => handleClick('/post')}>
                                <div className="col mb-3">
                                    <div className="border-bottom pb-3">
                                        <h5 className="mb-1">Looking to Boost Innovation? Partner with a Startup.</h5>
                                        <p className="mt-1 mb-1 font-weight-bold author text-lighter">Frederick Forsyth</p>
                                        <span class="bg-lighter p-1 small rounded">Podcast</span>

                                    </div>
                                </div>
                            </div>
                            <div className="row" onClick={() => handleClick('/post')}>
                                <div className="col mb-3">
                                    <div className="pb-3">
                                        <h5 className="mb-1">Rescuing Scientific Innovation from Corporate Bureaucracy</h5>
                                        <p className="mt-1 mb-1 font-weight-bold author text-lighter">Robert Ludlum</p>
                                        <span class="bg-lighter p-1 small rounded">Collection</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-black py-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col">
                            <h3 style={{ color: 'white', fontWeight: 400 }}>Leadership</h3>
                        </div>
                        <div className="col text-right pr-2">
                            <span className="p-1" style={{ color: 'white', fontWeight: 400 }}>Next <FontAwesomeIcon icon={faChevronRight} size="sm" /> </span>
                        </div>
                    </div>

                    <div className="row text-white category-block dark-background">
                        <div className="col-md-8">
                            <div className="row" onClick={() => handleClick('/program')}>
                                <div className="col">
                                    <div className="pb-3">
                                        <div className="row">
                                            <div className="col-md-6 mb-2">
                                                <div className="featured-image" style={{
                                                    backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Flarge_sOVMLTyzbCT0efsBqZOALoBXn82LDFgxYIA1ZHwlEBo.jpg?alt=media&token=e26fb268-c1b3-44b2-a30c-72fc7b6aabee)",
                                                }}
                                                ></div>
                                            </div>
                                            <div className="col-md-6">
                                                <h3>Strategic Leadership of Industry 4.0</h3>
                                                <span class="bg-dark p-1 small rounded">Executive Program</span>
                                                <p className="small mb-0 mt-3"><FontAwesomeIcon icon={faAward} size="1x" style={{ color: "orange" }} /> Attested with a record of attendance from the<br /> <strong>University of California, Los Angeles (UCLA Extension)</strong></p>
                                                <img width="150"
                                                    src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=b58a0dd8-71db-4489-b8bf-29293a09c252"
                                                />
                                            </div>
                                            <div className="col-12 mt-3 d-none d-md-block">
                                                <div className="col-12 border-bottom"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" onClick={() => handleClick('/program')}>
                                <div className="col">
                                    <div className="pb-3">
                                        <div className="row">
                                            <div className="col-md-6 mb-2">
                                                <div className="featured-image" style={{
                                                    backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe)",
                                                }}
                                                ></div>
                                            </div>
                                            <div className="col-md-6">
                                                <h3>Leading Academic Institutions in Turbulent Times</h3>
                                                <span class="bg-dark p-1 small rounded">Executive Program</span>
                                                <p className="small mb-0 mt-3"><FontAwesomeIcon icon={faAward} size="1x" style={{ color: "orange" }} /> Attested with a record of attendance from the<br /> <strong>University of California, Los Angeles (UCLA Extension)</strong></p>
                                                <img width="150"
                                                    src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=b58a0dd8-71db-4489-b8bf-29293a09c252"
                                                />
                                            </div>
                                            <div className="col-12 mt-3 d-block d-md-none">
                                                <div className="col-12 border-bottom"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 border-left">
                            <div className="row" onClick={() => handleClick('/post')}>
                                <div className="col mb-3">
                                    <div className="border-bottom pb-3">
                                        <h5 className="mb-1">Innovation Isn’t an Idea Problem</h5>
                                        <p className="mt-1 mb-1 font-weight-bold author">Stephen King</p>
                                        <span class="bg-dark p-1 small rounded">Collection</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row" onClick={() => handleClick('/post')}>
                                <div className="col mb-3">
                                    <div className="border-bottom pb-3">
                                        <h5 className="mb-1">A Post-Pandemic Strategy for U.S. Higher Ed</h5>
                                        <p className="mt-1 mb-1 font-weight-bold author">David Baldacci</p>
                                        <span class="bg-dark p-1 small rounded">Article</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row" onClick={() => handleClick('/post')}>
                                <div className="col mb-3">
                                    <div className="border-bottom pb-3">
                                        <h5 className="mb-1">Looking to Boost Innovation? Partner with a Startup.</h5>
                                        <p className="mt-1 mb-1 font-weight-bold author">Frederick Forsyth</p>
                                        <span class="bg-dark p-1 small rounded ">Podcast</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row" onClick={() => handleClick('/post')}>
                                <div className="col mb-3">
                                    <div className="pb-3">
                                        <h5 className="mb-1">Rescuing Scientific Innovation from Corporate Bureaucracy</h5>
                                        <p className="mt-1 mb-1 font-weight-bold author">Robert Ludlum</p>
                                        <span class="bg-dark p-1 small rounded">Collection</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Academy;