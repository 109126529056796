import React, { useEffect, useState } from 'react';
import { Card, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
    Link, useHistory
} from "react-router-dom";
import AcademyNavbar from './AcademyNavbar'
import firebase from 'firebase';

const categoryoptions = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Cateogry</Popover.Title>
        <Popover.Content>
            And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
);

const Category = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Category</Button>
    </OverlayTrigger>
);

const Dates = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Dates</Button>
    </OverlayTrigger>
);

function Academy() {

    const handleChange = function (event) {
        console.log(event.target.value)
    }

    const history = useHistory();

    function handleClick() {
        history.push("/program");
    }

    const [programList, setProgramList] = useState([]);
    const [loadingProgramList, setLoadingProgramList] = useState(true);

    useEffect(() => {

        let programListRef = firebase.firestore().collection('programList');
        let allPrograms = programListRef.orderBy("position", "asc").get()
            .then(snapshot => {
                let tempProgramList = [];

                snapshot.forEach(doc => {
                    tempProgramList.push({ programSlug: doc.id, ...doc.data() });
                });
                setProgramList(tempProgramList);
                setLoadingProgramList(false);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });

    }, [])

    return (
        <>
            <AcademyNavbar />
            <div className="container-fluid bg-black pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col text-white py-4">
                            <h1 className="font-weight-light">Everything you need to be future ready</h1>
                        </div>
                    </div>
                </div>
                {/* <div className="container">
                    <div className="row">
                        <div className="col text-white py-4">
                            <h1 className="font-weight-light">Everything you need to be future ready</h1>
                        </div>
                    </div>
                    <div className="row mb-5 text-white header">

                        <div className="col-md-4" onClick={handleClick} style={{ cursor: "pointer" }}>
                            <div className="featured-image"
                                style={{
                                    backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Flarge_DH8mwmZn6VS2MyqBRzug3gHIF6YMfNa4za0MxeZHpyo.jpg?alt=media&token=c421c5d3-3eae-445a-86ff-85153f99428a)",
                                    backgroundPosition: "center center",
                                }}>
                            </div>
                            <h4 className="mt-2 mb-2">Executive Programs</h4>
                            <p className="small" style={{ color: "#CCC" }}>Live virtual learning with faculty from top schools across the globe with excellent networking opportunities</p>
                        </div>
                        <div className="col-md-4">
                            <div className="featured-image"
                                style={{
                                    backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Flarge_55u78LugE1_tq0aLB3UF2F8KLF_sG-pD6urviQs5oMQ.jpg?alt=media&token=aad3f0ef-86b3-411f-a3a9-8284ceb763df)",
                                    backgroundPosition: "center center"
                                }}>
                            </div>
                            <h4 className="mt-2">Knowledge</h4>
                            <p className="small" style={{ color: "#CCC" }}>Get curated list of articles, podcasts and videos by domain experts to stay on top of your industry</p>
                        </div>
                        <div className="col-md-4">
                            <div
                                className="featured-image"
                                style={{
                                    backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Flarge_gWYBzrU3cUzwIoCg6vN-psmrO4Q-AnOPAL4iJY58_Rw.jpg?alt=media&token=c50c14ee-3090-4e7f-bda5-1e3626651f0b)",
                                    backgroundPosition: "center center"
                                }}>
                            </div>
                            <h4 className="mt-2">Consult Experts</h4>
                            <p className="small" style={{ color: "#CCC" }}>Get professional advice from top faculty and executives across the globe</p>
                        </div>
                    </div>
                </div>
             */}
            </div>
            <div className="container-fluid bg-black pt-5">
                <div className="container">

                    {/* <div className="row mb-5">
                        <NavDropdown className="mb-3 text-white custom-dropdown"
                            title={<Button variant="outline-light">Interests</Button>}
                            id="basic-nav-dropdown">
                            <div className="row mx-1 fixed-part">
                                <div className="col">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="interest1" />
                                        <label class="form-check-label" for="interest1">Finance</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="interest2" />
                                        <label class="form-check-label" for="interest2">Innovation</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="interest3" />
                                        <label class="form-check-label" for="interest3">Leadership</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="interest4" />
                                        <label class="form-check-label" for="interest4">Marketing</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="interest5" />
                                        <label class="form-check-label" for="interest5">Management</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="interest6" />
                                        <label class="form-check-label" for="interest6">Public Policy</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="interest7" />
                                        <label class="form-check-label" for="interest7">Social Impact</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="interest8" />
                                        <label class="form-check-label" for="interest8">Technology</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-1 mt-2 mb-1">
                                <div className="col"><Button>Save</Button></div>
                            </div>
                        </NavDropdown>

                        <NavDropdown className="mb-3 text-white custom-dropdown"
                            title={<Button variant="outline-light">Language</Button>}
                            id="language-nav-dropdown">
                            <div className="row mx-1 fixed-part">
                                <div className="col">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="interest1" />
                                        <label class="form-check-label" for="interest1">English</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="interest2" />
                                        <label class="form-check-label" for="interest2">Portuguese</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="interest3" />
                                        <label class="form-check-label" for="interest3">Hindi</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-1 mt-2 mb-1">
                                <div className="col"><Button>Save</Button></div>
                            </div>
                        </NavDropdown>
                        <NavDropdown className=" mb-3 text-white custom-dropdown"
                            title={<Button variant="outline-light">Time of day</Button>}
                            id="language-nav-dropdown">
                            <div className="row mx-1 fixed-part">
                                <div className="col">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="interest1" />
                                        <label class="form-check-label" for="interest1">Morning <br /><small>Starts before noon</small></label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="interest2" />
                                        <label class="form-check-label" for="interest2">Afternoon <br /><small>Starts after noon</small></label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="interest3" />
                                        <label class="form-check-label" for="interest3">Evening <br /><small>Starts after 5pm</small></label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-1 mt-2 mb-1">
                                <div className="col"><Button>Save</Button></div>
                            </div>
                        </NavDropdown>

                        <NavDropdown className="mb-3 text-white custom-dropdown"
                            title={<Button variant="outline-light">Price</Button>}
                            id="basic-nav-dropdown"
                        >
                            <div className="row mx-3">
                                <div class="form-group" style={{ width: "250px" }}>
                                    <div className="row small">
                                        <div className="col-6">
                                            USD 10
                                            </div>
                                        <div className="col-6 text-right">
                                            USD 100,000
                                            </div>
                                    </div>
                                    <input type="range"
                                        class="form-control-range"
                                        id="formControlRange"
                                        onChange={handleChange} />
                                </div>
                            </div>
                        </NavDropdown>
                    </div> */}

                    {loadingProgramList
                        ? <div className="container-fluid w-100 d-flex justify-content-center align-items-center" style={{ height: "20vh" }}>
                            <Spinner animation="border" role="status" variant="light">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                        : <div className="row">
                            {programList.map((item, key) => (
                                <div className="col-md-3" key={key}>
                                    <Link className="link" to={`/program/${item.programSlug}`}>
                                        <Card className="mb-3" bg="transparent" text="white">
                                            <Card.Img variant="top" src={item.programThumbnail} />
                                            <Card.Body className="px-0">
                                                <Card.Title>{item.programTitle}</Card.Title>
                                                <Card.Text className="small">{item.programSubTitle}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                            ))}
                            {/* <div className="col-md-3">
                            <Link className="link" to="/program">
                                <Card className="mb-3" bg="transparent" text="white">
                                    <Card.Img variant="top" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe" />
                                    <Card.Body className="px-0">
                                        <Card.Title>Leading Digital Transformation and Innovation</Card.Title>
                                        <Card.Text className="small">
                                            Leverage the power of digital technologies for competitive advantage
                                            Explore
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Card className="mb-3" bg="transparent" text="white">
                                <Card.Img variant="top" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe" />
                                <Card.Body className="px-0">
                                    <Card.Title>Strategic Leadership of Industry 4.0</Card.Title>
                                    <Card.Text className="small">
                                        Smart Manufacturing for the 4th Industrial Revolution
                            </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-3">
                            <Card className="mb-3" bg="transparent" text="white">
                                <Card.Img variant="top" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe" />
                                <Card.Body className="px-0">
                                    <Card.Title>Leading Academic Institutions in Turbulent Times</Card.Title>
                                    <Card.Text className="small">
                                        Chart a course of success for your academic institution in the post COVID world
                            </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-3">
                            <Card className="mb-3" bg="transparent" text="white">
                                <Card.Img variant="top" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe" />
                                <Card.Body className="px-0">
                                    <Card.Title>Artificial Intelligence: Principles and Techniques</Card.Title>
                                    <Card.Text className="small">
                                        Leverage Artificial Intelligence for competitive advantage
                            </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-3">
                            <Card className="mb-3" bg="transparent" text="white">
                                <Card.Img variant="top" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe" />
                                <Card.Body className="px-0">
                                    <Card.Title>Beyond Blue Ocean Strategy</Card.Title>
                                    <Card.Text className="small">
                                        Leverage Blue Ocean Strategy for Customer Innovation in a Turbulent World
                            </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-3">
                            <Card className="mb-3" bg="transparent" text="white">
                                <Card.Img variant="top" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe" />
                                <Card.Body className="px-0">
                                    <Card.Title>Cyber Security for Digital Leadership</Card.Title>
                                    <Card.Text className="small">
                                        Combat cyber security risk for strategic and competitive advantage
                            </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-3">
                            <Card className="mb-3" bg="transparent" text="white">
                                <Card.Img variant="top" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe" />
                                <Card.Body className="px-0">
                                    <Card.Title>Intellectual Property Management: An Enterprise Imperative</Card.Title>
                                    <Card.Text className="small">
                                        Chart a course of success for your academic institution in the post COVID world
                            </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-3">
                            <Card className="mb-3" bg="transparent" text="white">
                                <Card.Img variant="top" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe" />
                                <Card.Body className="px-0">
                                    <Card.Title>Innovation Management: Strategy and Execution</Card.Title>
                                    <Card.Text className="small">
                                        Leverage the innovative potential of your organization
                            </Card.Text>
                                </Card.Body>
                            </Card>
                        </div> */}
                        </div>}

                </div>
            </div>
        </>
    );
}

export default Academy;