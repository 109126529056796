import React, { useState } from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Academy from "./components/Academy/Academy"
import Program from "./components/Academy/Program"
import Consult from "./components/Academy/Consult"
import Post from "./components/Academy/Post"
import Programs from "./components/Academy/Programs"
import Insights from "./components/Insights/insights"
import Home from "./components/Home"

import FacultyList from "./components/Academy/FacultyList"
import Faculty from "./components/Academy/Faculty"
import Speciality from "./components/Academy/Speciality"
import Health from "./components/Academy/Health"

import './styles.scss';
import { Alert, Button } from 'react-bootstrap'


export default function App() {
  const [show, setShow] = useState(true);

  return (
    <Router>
      <div>
        {/* <Alert className="rounded-0 py-2 mb-0" show={show} variant="dark" >
          <div className="container">
            <div className="row">
              <div className="col-9 col-md-10">
                <p className="mb-0 small">
                  Please note that this website is for internal consumption only and not intended for external distribution.
              </p>
              </div>
              <div className="col-3 col-md-2 text-right">
                <p onClick={() => setShow(false)} className="small text-danger mb-0" style={{ cursor: "pointer" }}>Close</p>
              </div>
            </div>
          </div>
        </Alert> */}



        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/academy">
            <Academy />
          </Route>

          <Route path="/all-faculty">
            <FacultyList />
          </Route>

          <Route
            exact
            path="/doctor/:id"
            render={(props) => (
              <Faculty {...props} />
            )} />

          <Route path="/insights">
            <Insights />
          </Route>

          <Route path="/programs">
            <Programs />
          </Route>

          <Route exact path="/program/:id" component={Program} />
          {/* <Program />
          </Route> */}
          
          <Route path="/post">
            <Post />
          </Route>

          <Route
            exact
            path="/speciality/:slug"
            component={Speciality} />

          <Route path="/consult">
            <Consult />
          </Route>

          <Route path="/">
            {/* <Programs /> */}
            <Health />
          </Route>

        </Switch>
      </div>
    </Router>
  );
}