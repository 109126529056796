import React, { useEffect, useState } from 'react';
import { Card, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import {
    Link, useHistory
} from "react-router-dom";
import AcademyNavbar from './AcademyNavbar'
import firebase from 'firebase';

function Speciality(props) {

    const [doctorList, setDoctorList] = useState([]);
    const [loadingDoctorList, setLoadingDoctorList] = useState(true);
    const [specialityData, setSpecialityData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        // alert(JSON.stringify(props.match.params.slug));

        let doctorListRef = firebase.firestore().collection('doctorList');
        doctorListRef.get()
            .then(snapshot => {
                let tempDoctorList = [];

                snapshot.forEach(doc => {
                    tempDoctorList.push({ facultyId: doc.id, ...doc.data() });
                });
                setDoctorList(tempDoctorList);
                setLoadingDoctorList(false);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });

        firebase.firestore().collection("specialityList").where("slug", "==", props.match.params.slug).get()
            .then((specialityQS) => {
                specialityQS.forEach(specialityDoc => {
                    setSpecialityData(specialityDoc.data())
                    console.log(specialityDoc.data())
                });
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });

    }, [])

    return (
        <div>
            <AcademyNavbar />
            <div className="container-fluid bg-black pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col py-4">
                            {/* {isLoading
                            ? <Spinner animation="border" variant="dark" />
                            : <h1 className="font-weight-light">{specialityData.name}</h1>} */}
                            {isLoading
                            ? <h1 className="font-weight-light text-white">Speciality</h1>
                            : <h1 className="font-weight-light">{specialityData.name}</h1>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-black pt-5">
                <div className="container">
                    <h3 className="font-weight-light mb-3">Popular Doctors</h3>

                    {loadingDoctorList
                        ? <div className="container-fluid w-100 d-flex justify-content-center align-items-center" style={{ height: "20vh" }}>
                            <Spinner animation="border" role="status" variant="dark">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                        : <div className="row">
                            {doctorList.map((item, key) => (
                                <div className="col-md-3" key={key}>
                                    <Link className="link" to={{pathname: `/doctor/${item.id}`, query: {item}}}>
                                        <Card className="mb-3 border-0 shadow-sm" bg="transparent" text="dark">
                                            <Card.Img variant="top" src={`https://via.placeholder.com/800x533?text=${item.firstName} ${item.lastName}`} />
                                            <Card.Body>
                                                <Card.Title>{item.designation} {item.firstName} {item.lastName}</Card.Title>
                                                <Card.Text className="small">{item.college}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                            ))}
                        </div>}

                </div>
            </div>
        </div>
    )
}

export default Speciality;