import React, { useEffect, useState } from 'react';
import { Card, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
    Link, useHistory
} from "react-router-dom";
import AcademyNavbar from './AcademyNavbar'
import firebase from 'firebase';

const categoryoptions = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Cateogry</Popover.Title>
        <Popover.Content>
            And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
);

const Category = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Category</Button>
    </OverlayTrigger>
);

const Dates = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Dates</Button>
    </OverlayTrigger>
);

function DoctorList() {

    const handleChange = function (event) {
        console.log(event.target.value)
    }

    const history = useHistory();

    function handleClick() {
        history.push("/program");
    }

    const [doctorList, setDoctorList] = useState([]);
    const [loadingDoctorList, setLoadingDoctorList] = useState(true);

    useEffect(() => {

        let doctorListRef = firebase.firestore().collection('doctorList');
        doctorListRef.get()
            .then(snapshot => {
                let tempDoctorList = [];

                snapshot.forEach(doc => {
                    tempDoctorList.push({ facultyId: doc.id, ...doc.data() });
                });
                setDoctorList(tempDoctorList);
                setLoadingDoctorList(false);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });

    }, [])

    return (
        <>
            <AcademyNavbar />
            <div className="container-fluid bg-black pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col py-4">
                            <h1 className="font-weight-light">High quality affordable healthcare & wellness for all.</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-black pt-5">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-2 col-6 pb-2">
                            <Link className="link" to={{pathname: `/speciality/internal-family-medicine`}}>
                                <div className="bg-dark text-white p-3 rounded" style={{height:"100px"}}>
                                    <h5>Internal & Family Medicine</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-6 pb-3">
                            <Link className="link" to={{pathname: `/speciality/gynaecologist`}}>
                                <div className="bg-dark text-white p-3 rounded" style={{height:"100px"}}>
                                    <h5>Gynaecologist</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-6 pb-3">
                            <Link className="link" to={{pathname: `/speciality/orthopaedic`}}>
                                <div className="bg-dark text-white p-3 rounded" style={{height:"100px"}}>
                                    <h5>Orthopaedic</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-6 pb-3">
                            <Link className="link" to={{pathname: `/speciality/dermatologist`}}>
                                <div className="bg-dark text-white p-3 rounded" style={{height:"100px"}}>
                                    <h5>Dermatologist</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-6 pb-3">
                            <Link className="link" to={{pathname: `/speciality/ent`}}>
                                <div className="bg-dark text-white p-3 rounded" style={{height:"100px"}}>
                                    <h5>ENT</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-6 pb-3">
                            <Link className="link" to={{pathname: `/speciality/fertility-specialist`}}>
                                <div className="bg-dark text-white p-3 rounded" style={{height:"100px"}}>
                                    <h5>Fertility Specialist</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-6 pb-3">
                            <Link className="link" to={{pathname: `/speciality/eye-sepcialist`}}>
                                <div className="bg-dark text-white p-3 rounded" style={{height:"100px"}}>
                                    <h5>Eye Sepcialist</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-6 pb-3">
                            <Link className="link" to={{pathname: `/speciality/child-specialist`}}>
                                <div className="bg-dark text-white p-3 rounded" style={{height:"100px"}}>
                                    <h5>Child Specialist</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-6 pb-3">
                            <Link className="link" to={{pathname: `/speciality/urologist`}}>
                                <div className="bg-dark text-white p-3 rounded" style={{height:"100px"}}>
                                    <h5>Urologist</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-6 pb-3">
                            <Link className="link" to={{pathname: `/speciality/dentist`}}>
                                <div className="bg-dark text-white p-3 rounded" style={{height:"100px"}}>
                                    <h5>Dentist</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-6 pb-3">
                            <Link className="link" to={{pathname: `/speciality/hair-specialist`}}>
                                <div className="bg-dark text-white p-3 rounded" style={{height:"100px"}}>
                                    <h5>Hair Specialist</h5>
                                </div>
                            </Link>
                        </div>
                    </div>

                    {loadingDoctorList
                        ? <div className="container-fluid w-100 d-flex justify-content-center align-items-center" style={{ height: "20vh" }}>
                            <Spinner animation="border" role="status" variant="dark">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                        : <div className="row">
                            {doctorList.map((item, key) => (
                                <div className="col-md-3" key={key}>
                                    <Link className="link" to={{pathname: `/doctor/${item.id}`, query: {item}}}>
                                        <Card className="mb-3 border-0 shadow-sm" bg="transparent" text="dark">
                                            <Card.Img variant="top" src={`https://via.placeholder.com/800x533?text=${item.firstName} ${item.lastName}`} />
                                            <Card.Body>
                                                <Card.Title>{item.designation} {item.firstName} {item.lastName}</Card.Title>
                                                <Card.Text className="small">{item.college}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                            ))}
                        </div>}

                </div>
            </div>
        </>
    );
}

export default DoctorList;