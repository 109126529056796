import React, { useState } from "react";
import firebase from "firebase";
import { Link } from "react-router-dom";
import * as DeviceDetect from "react-device-detect";
import { Spinner } from "react-bootstrap";
import './brochure.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";


class RequestBrochure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicFormCompleted: false,
            phoneNumber: "+1",
            errorPhoneNumber: {},
            email: "",
            errorEmail: "",
            firstName: "",
            errorFirstName: "",
            lastName: "",
            errorLastName: "",

            loadingSendOtp: false,
            otpSent: false,
            otp: "",
            errorOtp: "",
            loadingConfirmOtp: false,
            otpConfirmed: false,
            firstPhaseDone: false,

            leadId: "",
            previousUserData: {},
            previousEmail: "",
            previousEmailVerified: false,
            promptForEmail: false,
            selectedEmail: "",

            emailSentCard: false,
            emailSentTo: "",

            updateEmailCard: false,
            updatedEmail: "",
            confirmUpdatedEmail: "",
            emailNotFound: false,

            checkingUserData: true,


            usersRef: firebase.database().ref("users"),

            resendEmail: false,
            loadingResendEmail: false,

            changeEmail: false,
            loadingConfirmEmail: false,
            confirmEmail: "",
            checkConfirmEmail: false
        };
    }

    async componentDidMount() {
        // Turn off phone auth app verification.
        // firebase.auth().settings.appVerificationDisabledForTesting = true;
        // if (firebase.auth().currentUser) {
        //     firebase.auth().signOut().then(() => console.log("signed out!"));
        // }

        // console.log("this.props", this.props);

        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
            size: "invisible"
        });


        // NEW APPROACH

        firebase.auth().onAuthStateChanged(async (user) => {

            if (user) {
                const {
                    leadId,
                    firstName,
                    lastName,
                    email,
                    selectedEmail,
                    previousEmail
                } = this.state;

                this.setState({ checkingUserData: false });

                this.getData(firebase.database().ref("users").child(user.uid))
                    .then((value) => {
                        this.setState({
                            basicFormCompleted: true,
                            previousUserData: value,
                            firstName: value.firstName,
                            lastName: value.lastName
                        })

                        if (value.email) {

                            if (value.emailVerified) {
                                // alert("User + Email found & verified")
                                this.setState({
                                    previousEmail: value.email,
                                    previousEmailVerified: true,
                                    promptForEmail: true
                                })

                            } else {
                                // alert("User + Email found, but email not verified")
                                this.setState({
                                    previousEmail: value.email,
                                    previousEmailVerified: false,
                                    promptForEmail: true
                                })

                            }
                        } else {
                            // alert("Only User found, but email does not found")

                            console.log("Please add an email")
                            this.setState({ updateEmailCard: true, emailNotFound: true })
                            if (this.state.email) {
                                this.setState({ updatedEmail: this.state.email })
                            }

                            // console.log("Generate Lead")
                            // this._generateLead(user, email);
                        }

                    })
                    .catch((error) => {
                        console.log("User didn't found, ", error);
                        // alert("User didn't found")

                        // console.log("Generate Lead")
                        this._generateLead(user);


                        user.updateProfile({
                            displayName: firstName + " " + lastName,
                            photoURL: 'https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/avatars%2Fusers%2Fsolo-icon.svg?alt=media&token=0279ff83-ebf4-420f-bc28-e5f6b10a7822'
                        })
                            .then(() => {
                                firebase
                                    .database()
                                    .ref("users")
                                    .child(user.uid)
                                    .set({
                                        leadId: leadId,
                                        timestamp: firebase.database.ServerValue.TIMESTAMP,
                                        displayName: user.displayName,
                                        name: user.displayName,
                                        firstName: firstName,
                                        lastName: lastName,
                                        avatar: user.photoURL,
                                        phoneNumber: user.phoneNumber,
                                        email: email,
                                        emailVerified: true,
                                        providerData: user.providerData,
                                        uid: user.uid,
                                        deviceInfo: DeviceDetect.deviceDetect(),
                                        deviceType: DeviceDetect.deviceType,
                                        device: DeviceDetect.isMobile ? DeviceDetect.isMobileOnly ? "Mobile" : "Tablet" : "Desktop",
                                        electron: DeviceDetect.isElectron ? true : false
                                    }).then(() => {
                                        console.log("user saved");
                                    });
                            })
                            .catch(err => {
                                console.error(err);
                            });

                    });
            } else {
                this.setState({ checkingUserData: false });
            }
        })

    }


    _handlePromptWhichEmailToUse = async (selectedEmail) => {
        const user = firebase.auth().currentUser;

        // console.log("Generate Lead", user, selectedEmail)
        let leadGeneratedDone = await this._generateLead(user, selectedEmail);

        if (leadGeneratedDone) {
            this.setState({
                emailSentCard: true,
                emailSentTo: selectedEmail,
                updateEmailCard: false,
                promptForEmail: false,
                loadingConfirmEmail: false
            })
        }
    }


    _generateLead = async (user, previousEmail = this.state.email) => {
        const { firstName, lastName } = this.state;
        const { programId, programType } = this.props;
        let sendBrochure = firebase.functions().httpsCallable('sendBrochure');

        // console.log("Inside Generate Lead", {
        //     firstName: firstName,
        //     lastName: lastName,
        //     email: previousEmail,
        //     phoneNumber: user.phoneNumber,
        //     uid: user.uid,
        //     programId: programId,
        //     programType: programType,
        //     timestamp: firebase.firestore.FieldValue.serverTimestamp()
        // })

        if (
            this._checkIfStringEmpty(firstName)
            && this._checkIfStringEmpty(lastName)
            && this._checkIfStringEmpty(previousEmail)
            && this._checkIfEmailValid(previousEmail)
            && this._checkIfStringEmpty(programId)
            && this._checkIfStringEmpty(programType)
        ) {
            let leadData = {
                firstName: firstName,
                lastName: lastName,
                email: previousEmail,
                phoneNumber: user.phoneNumber,
                uid: user.uid,
                programId: programId,
                programType: programType,
                timestamp: firebase.firestore.FieldValue.serverTimestamp()
            };

            let leadGenerated = await firebase
                .firestore()
                .collection("leads")
                .add(leadData)
                .then(function (docRef) {
                    // console.log("Document written with ID: ", docRef.id);

                    sendBrochure({
                        leadGenerationId: docRef.id
                    })
                        .then(function (result) {
                            console.log("sendBrochure", result)
                        });

                    firebase
                        .database()
                        .ref("users")
                        .child(user.uid)
                        .update({
                            email: previousEmail,
                            emailVerified: true
                        })

                    return docRef.id;
                })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                    return false;
                });

            if (leadGenerated) {
                this.setState({ leadId: leadGenerated })
                return true;
            } else {
                console.log("Cannot generate lead with this data, ", leadData);
                return false;
            }

        }
    }


    getData = (ref) => {
        return new Promise((resolve, reject) => {
            const onError = (error) => reject(error);
            const onData = (snap) => resolve(snap.val());

            ref.on("value", onData, onError);
        });
    };



    // NEW APPROACH

    _handleChange = event => {
        var pattern = /^\+[0-9\s\-\(\)]+$/;
        var patternOtp = /^[0-9]*$/;
        if (event.target.name === "phoneNumber") {

            if (event.target.value.match(pattern))
                this.setState({ [event.target.name]: event.target.value });

        } else if (event.target.name === "otp") {

            if (event.target.value.length <= 6 && event.target.value.match(patternOtp))
                this.setState({ [event.target.name]: event.target.value });

        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    };


    _checkIfStringEmpty = val => val.length > 0;

    _checkIfEmailValid = email => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    _checkIfFormValid = () => {
        const { firstName, lastName, email, phoneNumber } = this.state;

        if (
            this._checkIfStringEmpty(firstName)
            && this._checkIfStringEmpty(lastName)
            && this._checkIfStringEmpty(email)
            && this._checkIfStringEmpty(phoneNumber)
            && this._checkIfEmailValid(email)
        ) {
            return true;
        }
        return false;
    }


    _handleSubmitAndGenerateOTP = event => {
        const { phoneNumber } = this.state;
        event.preventDefault();

        if (this._checkIfFormValid()) {

            this.setState({ loadingSendOtp: true });

            const appVerifier = window.recaptchaVerifier;

            firebase
                .auth()
                .signInWithPhoneNumber(phoneNumber, appVerifier)
                .then(confirmResult => {
                    // console.log("phoneNumber", phoneNumber);
                    // console.log("confirmResult", confirmResult);
                    window.confirmationResult = confirmResult;
                    this.setState({
                        otpSent: true,
                        loadingSendOtp: false
                    });
                })
                .catch(err => {
                    // console.log("phoneNumber", phoneNumber);
                    console.error(err);
                    this.setState({
                        errorPhoneNumber: err,
                        loadingSendOtp: false
                    });
                });
        }
    }


    _handleConfirmOTP = async () => {
        const { otp } = this.state;

        if (this._checkIfStringEmpty(otp)) {

            this.setState({ loadingConfirmOtp: true });

            let resultOfOTP = await window.confirmationResult.confirm(otp).then(function (result) {
                // User signed in successfully.
                // console.log("result", result);

                // var user = result.user;
                // console.log("user", user);

                // console.log("CurrentUser", firebase.auth().currentUser);
                return true;

            }).catch(function (error) {
                console.log(error);
                return false;
            });

            if (resultOfOTP) {
                console.log("OTP matches.")
                this.setState({ loadingConfirmOtp: false })
            } else {
                this.setState({
                    errorOtp: "Invalid OTP entered, please re-enter correct OTP.",
                    loadingConfirmOtp: false
                });
            }

        } else {
            this.setState({ errorOtp: "Please enter OTP" });
        }
    }

    _handleEmailUpdate = () => {
        const { updatedEmail, confirmUpdatedEmail } = this.state;
        this.setState({ loadingConfirmEmail: true });

        if (updatedEmail === confirmUpdatedEmail) {
            console.log("updatedEmail", updatedEmail)
            this._handlePromptWhichEmailToUse(updatedEmail);
        }
    }


    render() {
        const {
            phoneNumber,
            email,
            firstName,
            lastName,
            otp,
            loadingSendOtp,
            loadingConfirmOtp,
            loadingConfirmEmail
        } = this.state;

        return (
            <div className="app d-flex align-items-center justify-content-center">
                <div className="app-block">
                    <div as="h1" icon className="my-3 my-md-4">

                        {!this.state.basicFormCompleted
                            && <div>
                                <h5>Please fill in details to download brochure</h5>
                            </div>}

                        {this.state.emailSentCard
                            && <div>
                                <h3 className="text-center">Thank you</h3>
                                <p className="text-center">
                                    <span className="h5">We have sent a download link on your email, </span>
                                    <span className="ml-2 text-primary h5">{this.state.emailSentTo}</span>
                                </p>
                            </div>}

                    </div>
                    <form onSubmit={e => e.preventDefault()} size="large">
                        <div stacked>

                            {/* NEW APPROACH */}

                            {this.state.basicFormCompleted
                                ? <div>

                                    {this.state.updateEmailCard
                                        && <div>

                                            <h4>Hello, {this.state.previousUserData.displayName}</h4>
                                            {this.state.emailNotFound
                                                ? <>
                                                    <p>It seems like you already have account with Igesia using <span className="text-primary">{this.state.previousUserData.phoneNumber}</span>, but your email is not linked yet.</p>
                                                </>
                                                : <>
                                                    <p>Please enter and confirm your email to get download link.</p>
                                                </>}

                                            <h5>Update email</h5>
                                            <div className="form-label-group">
                                                <input
                                                    fluid
                                                    name="updatedEmail"
                                                    placeholder="yourname@email.com"
                                                    onChange={this._handleChange}
                                                    value={this.state.updatedEmail}
                                                    className="form-control inputField"
                                                    type="email"
                                                />
                                                <label for="updatedEmail" className="mb-1">Email</label>
                                            </div>
                                            <div className="form-label-group">
                                                <input
                                                    fluid
                                                    name="confirmUpdatedEmail"
                                                    placeholder="yourname@email.com"
                                                    onChange={this._handleChange}
                                                    value={this.state.confirmUpdatedEmail}
                                                    className="form-control inputField"
                                                    type="email"
                                                />
                                                <label for="confirmUpdatedEmail" className="mb-1">Confirm email</label>
                                            </div>
                                            <button
                                                disabled={loadingConfirmEmail}
                                                className="btn btn-dark"
                                                onClick={this._handleEmailUpdate}
                                            >
                                                {loadingConfirmEmail && <Spinner animation="border" size="sm" className="mr-2" />}
                                            Confirm email
                                        </button>
                                            {!this.state.emailNotFound
                                                && <button
                                                    className="btn btn-outline-dark border-0"
                                                    onClick={() => this.setState({ updateEmailCard: false, promptForEmail: true })}
                                                >
                                                    Cancel
                                            </button>}
                                        </div>}

                                    {this.state.promptForEmail && ((this.state.email) && (this.state.email !== this.state.previousEmail)
                                        ? <div>
                                            <h4>Hello, {this.state.previousUserData.displayName}</h4>
                                            <p>Looks like you already have an email <span className="font-weight-bold">{this.state.previousEmailVerified && "verified and "}attached</span> to your account <span className="text-primary">{this.state.previousEmail}</span>, do you wish to use this email?</p>

                                            <button
                                                className="btn btn-dark mt-3 mr-2"
                                                onClick={() => this._handlePromptWhichEmailToUse(this.state.previousEmail)}
                                            >
                                                {/* {loadingConfirmOtp && <Spinner animation="border" size="sm" className="mr-2" />} */}
                                                {/* Yes, use {this.state.previousEmail} */}
                                                Continue
                                            </button>
                                            <button
                                                className="btn btn-outline-dark border-0 mt-3"
                                                onClick={() => this._handlePromptWhichEmailToUse(this.state.email)}
                                            >
                                                {/* {loadingConfirmOtp && <Spinner animation="border" size="sm" className="mr-2" />} */}
                                                Use, {this.state.email}
                                            </button>
                                        </div>
                                        : <div>
                                            <h4>Hello, {this.state.previousUserData.displayName}</h4>
                                            <p>Looks like you already have an email <span className="font-weight-bold">{this.state.previousEmailVerified && "verified and "}attached</span> to your account <span className="text-primary">{this.state.previousEmail}</span>, do you wish to use this email?</p>

                                            <button
                                                className="btn btn-dark mt-3 mr-2"
                                                onClick={() => this._handlePromptWhichEmailToUse(this.state.previousEmail)}
                                            >
                                                {/* {loadingConfirmOtp && <Spinner animation="border" size="sm" className="mr-2" />} */}
                                                {/* Yes, use {this.state.previousEmail} */}
                                                Continue
                                            </button>
                                            <button
                                                className="btn btn-outline-dark border-0 mt-3"
                                                onClick={() => 
                                                    this.setState({ 
                                                        updateEmailCard: true, 
                                                        promptForEmail: false 
                                                    }, () => {
                                                        if (this.state.email) {
                                                            this.setState({ updatedEmail: this.state.email })
                                                        }
                                                    })
                                                }
                                            >
                                                Use another email
                                            </button>
                                        </div>)}
                                </div>
                                : <div>
                                    {!this.state.otpSent
                                        ? <div>
                                            <div className="form-label-group">
                                                <input
                                                    fluid
                                                    name="firstName"
                                                    placeholder="First name"
                                                    onChange={this._handleChange}
                                                    value={firstName}
                                                    className="form-control"
                                                    type="text"
                                                />
                                                <label for="firstName" className="mb-1">First name</label>
                                            </div>
                                            <div className="form-label-group">
                                                <input
                                                    fluid
                                                    name="lastName"
                                                    placeholder="Last name"
                                                    onChange={this._handleChange}
                                                    value={lastName}
                                                    className="form-control"
                                                    type="text"
                                                />
                                                <label for="lastName" className="mb-1">Last name</label>
                                            </div>
                                            <div className="form-label-group">
                                                <input
                                                    fluid
                                                    name="email"
                                                    placeholder="yourname@email.com"
                                                    onChange={this._handleChange}
                                                    value={email}
                                                    className="form-control"
                                                    type="email"
                                                />
                                                <label for="email" className="mb-1">Email</label>
                                            </div>
                                            <p className="mb-1">Enter your phone number to receive OTP</p>
                                            <div className="form-label-group mb-0">
                                                <input
                                                    fluid
                                                    name="phoneNumber"
                                                    placeholder="+1"
                                                    onChange={this._handleChange}
                                                    value={phoneNumber}
                                                    className="form-control"
                                                    type="tel"
                                                    pattern="\+[0-9\s\-\(\)]+"
                                                />
                                                <label for="phoneNumber" className="mb-1">Phone</label>
                                            </div>
                                            {this.state.errorPhoneNumber && <p className="small mb-1 mt-1 text-danger">{this.state.errorPhoneNumber.message}</p>}

                                            <div className="form-group mt-3">
                                                <button
                                                    disabled={loadingSendOtp}
                                                    className="btn btn-dark"
                                                    fluid
                                                    size="large"
                                                    onClick={this._handleSubmitAndGenerateOTP}
                                                >
                                                    {loadingSendOtp && <Spinner animation="border" size="sm" className="mr-2" />}
                                            Send OTP
                                        </button>
                                            </div>
                                        </div>
                                        : <div>
                                            <p className="mb-1">Please enter the OTP sent on <span className="text-primary">{phoneNumber}</span></p>
                                            <div className="form-label-group mb-0">
                                                <input
                                                    fluid
                                                    name="otp"
                                                    placeholder="123456"
                                                    onChange={this._handleChange}
                                                    value={otp}
                                                    className="form-control inputField"
                                                    maxLength="6"
                                                />
                                                <label for="otp" className="mb-1">Enter OTP</label>
                                            </div>
                                            {this.state.errorOtp !== "" && <p className="small mb-1 mt-1 text-danger">{this.state.errorOtp}</p>}

                                            <button
                                                disabled={loadingConfirmOtp}
                                                className="btn btn-dark mt-3"
                                                onClick={this._handleConfirmOTP}
                                            >
                                                {loadingConfirmOtp && <Spinner animation="border" size="sm" className="mr-2" />}
                                        Confirm OTP
                                    </button>
                                        </div>}
                                </div>}


                            <input id="recaptcha-container" type="hidden" />

                        </div>
                    </form>

                </div>
            </div>
        );
    };
}

export default RequestBrochure;