import React, { useState, useEffect } from 'react'
import { Card, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import AcademyNavbar from './AcademyNavbar'
import firebase from 'firebase';

function Faculty(props) {

    const [facultyData, setFacultyData] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        console.log(props.match.params.id)

        firebase.firestore().collection("doctorList").where("id", "==", props.match.params.id).get()
            .then((facultyQS) => {
                facultyQS.forEach(facultyDoc => {
                    setFacultyData(facultyDoc.data())
                    console.log(facultyDoc.data())
                });
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });

    }, []);

    return (
        <>
            <AcademyNavbar />
            {isLoading
                ? <div className="container-fluid w-100 d-flex justify-content-center align-items-center" style={{ height: "20vh" }}>
                    <Spinner animation="border" role="status" variant="dark">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
                : <>
                    <div className="container-fluid bg-black pt-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <img
                                        className="rounded"
                                        style={{ height: 120 }}
                                        src={`https://via.placeholder.com/800x533?text=${facultyData.firstName} ${facultyData.lastName}`} />
                                </div>
                                <div className="col-md-9">
                                    <h1 className="font-weight-light">{facultyData.designation} {facultyData.firstName} {facultyData.lastName}</h1>
                                    <p className="text-dark mb-1">{facultyData.college}</p>
                                    <p className="text-dark">{facultyData.university.name}{facultyData.university.location && `, ${facultyData.university.location}`}</p>
                                    <a className="btn btn-sm btn-primary text-white mb-3 small">Book Consultation</a>
                                    {facultyData.biography.split("\\n").map((item) => (
                                        <p className="text-dark">{item}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-black pt-5">
                        <div className="container">
                        </div>
                    </div>
                </>}
        </>
    )
}

export default Faculty;