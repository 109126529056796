import React, { useEffect, useState } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from './AcademyNavbar';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import RequestBrochure from './RequestBrochure';

library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb);


function AlertDismissible({ showAlert, handleClose, handleAlertHide }) {

    return (
        <div className="col-10" style={{ position: "absolute", zIndex: 10, top: "10%" }}>
            <Alert show={showAlert} variant="dark">
                <Alert.Heading>Confirm leave</Alert.Heading>
                <p>Are you sure you want to leave?</p>
                <hr />
                <div className="d-flex justify-content-end">
                    <Button
                    onClick={handleAlertHide} 
                    variant="light"
                    className="mx-2">
                        Cancel
                    </Button>
                    
                    <Button
                    onClick={handleClose} 
                    variant="dark"
                    className="mx-2">
                        Exit
                    </Button>
                </div>
            </Alert>
        </div>
    );
}


function RequestBrochureModal({ programId, programType }) {
    const [show, setShow] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const handleClose = () => {
        setShow(false);
        setShowAlert(false);
    };
    const handleShow = () => setShow(true);
    const handleAlertShow = () => setShowAlert(true);
    const handleAlertHide = () => setShowAlert(false);
    
    useEffect(() => {
        // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
        //     size:"invisible"
        // });
    }, [])

    return (
        <>
            <Button variant="outline-light" size="sm" onClick={handleShow}><p className="font-weight-bold mb-0">Request Brochure</p></Button>

            <Modal 
            onEscapeKeyDown={handleAlertShow} 
            onHide={handleAlertShow} 
            centered 
            show={show} 
            // onHide={handleClose}
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title>Request Brochure</Modal.Title>
                </Modal.Header> */}
                <Modal.Body className="border-white">
                    <div className="d-flex align-items-center justify-content-center">
                        <AlertDismissible showAlert={showAlert} handleClose={handleClose} handleAlertHide={handleAlertHide} />
                    </div>
                    <RequestBrochure programId={programId} programType={programType} />
                </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="light" onClick={handleClose}>Close</Button>
                        <Button variant="dark" onClick={handleClose}>Done</Button>
                    </Modal.Footer> */}
            </Modal>
        </>
    );
}


function Program(props) {
    const [price, setPrice] = useState(95000);
    const [count, setCount] = useState(0);
    const [programId, setProgramId] = useState("");
    const [programType, setProgramType] = useState("");
    const [aboutProgram, setAboutProgram] = useState('');
    const [certificationAuthority, setCertificationAuthority] = useState('');
    const [faculty, setFaculty] = useState([]);
    const [learningJourney, setLearningJourney] = useState({});
    const [learningObjectives, setLearningObjectives] = useState({});
    const [programFeatures, setProgramFeatures] = useState([]);
    const [programName, setProgramName] = useState('');
    const [programShortDescription, setProgramShortDescription] = useState('');
    const [tags, setTags] = useState([]);
    const [who, setWho] = useState({});
    const [loadingData, setLoadingData] = useState(true);


    function LiveClasses(sessions) {
        for (var i = 0; i < sessions; i++) {
            return (
                <>
                    <span className="livemarker">LIVE{count}</span>
                </>
            )
        }
    }

    useEffect(() => {
        console.log('Executing Initial useEffect Process.')
        console.log(props.match.params.id)
        // console.log(props)
        // firebase.firestore().doc('executivePrograms/IGESIA-EP-0001').get()
        firebase.firestore().doc(`programList/${props.match.params.id}`).get()
            .then((programListDoc) => {
                if (programListDoc.exists) {
                    const programListData = programListDoc.data();

                    setProgramId(programListData.programId);
                    setProgramType(programListData.programType);

                    programListData.programData.get().then((programDoc) => {
                        if (programDoc.exists) {

                            const programData = programDoc.data();

                            setAboutProgram(programData.aboutProgram);
                            setCertificationAuthority(programData.certificationAuthority);
                            setFaculty(programData.faculty);
                            setLearningJourney(programData.learningJourney);
                            setLearningObjectives(programData.learningObjectives);
                            setProgramFeatures(programData.programFeatures);
                            setProgramName(programData.programName);
                            setProgramShortDescription(programData.programShortDescription);
                            setTags(programData.tags);
                            setWho(programData.who);
                            setLoadingData(false);

                        } else {
                            console.log('Program Data not found.');
                        }
                    });
                } else {
                    console.log('Program Data not found.');
                }
            }).catch((error) => {
                console.log('Unable to fetch Program data.');
            });
    }, []);

    if (loadingData) {
        return (
            <div className="container-fluid w-100 d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <Spinner animation="border" role="status" variant="light">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        )
    }

    return (
        <div>
            <AcademyNavbar />
            <div className="container-fluid pricing-bar py-3">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-8">
                            <p className="font-weight-bold mb-0">
                                <span className="d-none d-md-block pt-2">{programName}</span>
                                <span className="d-block d-md-none">From ₹{price}/ person </span>
                            </p>
                        </div>
                        <div className="col-4 text-right">
                            <p className="font-weight-bold mb-0">
                                <span className="mr-2 d-none d-md-inline">From ₹{price}/ person </span>
                                {/* <Button variant="primary d-inline font-weight-bold mb-0" style={{ marginTop: '-5px' }}>See dates</Button> */}
                                <RequestBrochureModal programId={programId} programType={programType} />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-black pt-5">
                <div className="container">
                    <div className="row mb-5 flextry" style={{ width: "100%" }}>
                        <div class="flex-container">
                            {/* <div id="MainBanner" className="d-none d-md-block">
                                <video width="100%" controlsList="nodownload" controls loop muted style={{ maxWidth: "300px" }}>
                                    <source src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-video.mp4?alt=media&token=1eccfe7a-8468-4f89-874e-f6dfbcf69e38" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div> */}
                            <div className="cover-image"
                            style={{
                                backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FDr%20Bruno%20Lanvin.jpg?alt=media&token=a9814b94-3156-4612-9245-66b562ec8326)"
                            }}
                            ></div>
                            <div>
                                <div style={{ backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FAlice%20Li.jpg?alt=media&token=0717ae51-18a1-4cd0-b37b-eb5cfb8daa75)" }}></div>
                                <div style={{ 
                                    backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FLutz%20Finger_Prof.jpg?alt=media&token=eda6c7ec-b3b0-45f1-bdd0-2ea69fc494d8)" }}></div>
                            </div>
                            <div style={{
                                backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FA_Michael_Spence.jpg?alt=media&token=c6e72bec-3451-406b-aaf4-bd537695c6a7)"
                            }}
                            ></div>
                            <div style={{
                                backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FYG5Z7272.jpg?alt=media&token=d5b7ed00-fe18-4deb-b873-dcd69f615f7e)"
                            }}
                            ></div>

                        </div>
                    </div>

                    <div className="row program-header pb-5">
                        <div className="col-md-6 text-white mb-3">
                            <h1 className="font-weight-light h2">{programName}</h1>
                            <p className="font-weight-light lead">{programShortDescription}</p>
                            {!loadingData && tags.map(item => (
                                <span key={item} class="badge text-uppercase badge-dark mr-2 mb-2 font-weight-normal p-2">{item}</span>
                            ))}
                        </div>
                        <div className="col-md-6 text-white ">
                            <div className="border-dark border-top border-bottom py-2">
                                <div className="row">
                                    <div className="col-md-1 col-1 pt-2 "><FontAwesomeIcon icon={faAward} size="2x" style={{ color: "orange" }} /></div>
                                    <div className="col-md-8 col-11 certification-text">Attested with a record of attendance from the<br /> <strong>University of California, Los Angeles (UCLA) Extension</strong></div>
                                    <div className="col-md-3 pt-2 ">
                                        <img className="logo" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=adbdeab7-41e8-4d9a-a1a7-9f2d8fb3f5ac"
                                            width="100%" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                {
                                    !loadingData && programFeatures.map(feature => (
                                        <div className="col-6">
                                            <FontAwesomeIcon icon={feature.icon} color="grey" />
                                            <p className="small">{feature.content}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container-fluid bg-light py-5 program-content-web">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>About the Program</h2>
                            </div>
                            <div className="col-md-8 ">
                                {aboutProgram.split("\\n").map(item => (
                                    <p>{item}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5  program-content-web" style={{ background: "#EFEFEF" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>What you will learn</h2>
                            </div>
                            <div className="col-md-8 ">
                                <p>{!loadingData && learningObjectives.intro}</p>
                                <p>
                                    <ol>
                                        {
                                            !loadingData && learningObjectives.learningPoints.map(learningPoint => (
                                                <li><p><h3>{learningPoint.title}</h3>{learningPoint.content}</p></li>
                                            ))
                                        }
                                    </ol>
                                </p>
                                <p>{!loadingData && learningObjectives.learningOutcomes}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-light py-5 program-content-web">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>Record of Participation</h2>
                            </div>
                            <div className="col-md-8 ">
                                <p>Upon successful completion of the course, you will be awarded 
                                    a record of participation from the 
                                    University of California, Los Angeles (UCLA) Extension
                                </p>
                                <img src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Funex_certificate.png?alt=media&token=e273510f-6596-4ffc-9565-94149eee018d" width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5 text-white program-content-web">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>Your Learning Journey</h2>
                            </div>
                            <div className="col-md-8">
                                <p><span className="text-white">{learningJourney.intro}</span></p>
                                <div className="program-structure">
                                    {!loadingData && learningJourney.modules.map(item => (
                                        <p>
                                            {LiveClasses(item.sessions)}

                                            {item.moduleType !== ""
                                                ? item.moduleType == "Orientation Module"
                                                    ? <span className="d-block font-weight-bold text-uppercase text-warning small">{item.moduleType}</span>
                                                    : item.moduleType === "POST PROGRAM"
                                                        ? <span className="d-block font-weight-bold text-uppercase text-warning small">{item.moduleType}</span>
                                                        : <span className="d-block font-weight-bold text-uppercase text-primary small">{item.moduleType}</span>
                                                : null
                                            }
                                            {item.moduleName !== "" ? <h4>{item.moduleName}</h4> : null}
                                            {item.description !== ""
                                                ? item.description.split("\\n").map(item => (
                                                    <span className="font-weight-light">{item}<br /></span>
                                                ))
                                                : null}
                                        </p>
                                    ))}
                                </div>
                                <div className="program-structure">
                                    <p>
                                        <ul>
                                            <li>Knowledge updates</li>
                                            <li>Alumni Community</li>
                                            <li>Social interactions</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5 program-content-web" style={{ background: "#EFEFEF" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>Who should take this course?</h2>
                            </div>
                            <div className="col-md-8 ">
                                <p>{!loadingData && who.intro}</p>
                                <h5>This program will benefit you if</h5>
                                <p>
                                    <ul>
                                        {
                                            !loadingData && who.questions.map(question => (
                                                <li><p>{question}</p></li>
                                            ))
                                        }
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5 program-content-web bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>World Class Faculty</h2>
                            </div>
                            <div className="col-md-8 ">
                                {
                                    !loadingData && faculty.map(professor => (
                                        <p>
                                            <h5>{professor.facultyName}</h5>
                                            {
                                                professor.bio.split("\\n").map(para => (
                                                    <p>{para}</p>
                                                ))
                                            }
                                        </p>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Program;
