import React, { useEffect, useState } from 'react';
import { Card, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faChevronRight, faLocationArrow, faMapMarker, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import {
    Link, useHistory
} from "react-router-dom";
import AcademyNavbar from './AcademyNavbar'
import firebase from 'firebase';
import Longtext from './longtext';
import { faBell, faInfoCircle, faArrowLeft, faClipboard, faCalendarAlt, faBookOpen, faStar, faTimesCircle, faPoll, faFileAlt, faBook, faClipboardList, faList, faTasks, faCaretDown, faEdit, faFolderPlus, faFolder, faSignOutAlt, faTimes, faBars, faChalkboard, faChartLine, faCog, faCloud, faUsers, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import GoogleMapReact from 'google-map-react';

function RightFunctions() {
    return (
        <div className="container">
            <div className="row p-2">
                <div className="col-9">
                    <div className="row text-center bg-white rounded-lg pt-3">
                        <div className="col-md-4 col-sm-4">
                            <Button variant="light" size="sm" className="mr-2 mb-1 p-1 folder w-100 text-dark" style={{ height: "60px", borderRadius: "10px" }}>
                                <FontAwesomeIcon size="lg" icon={faFolderPlus} />
                            </Button>
                            <p className="smaller">Folder</p>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <Button variant="light" size="sm" className="mr-2 mb-1 schedule w-100 text-dark" style={{ height: "60px", borderRadius: "10px" }}>
                                <FontAwesomeIcon size="lg" icon={faCalendarAlt} />
                            </Button>
                            <p className="smaller">Schedule</p>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <Button variant="light" size="sm" className="mr-2 mb-1 reading w-100 text-dark" style={{ height: "60px", borderRadius: "10px" }}>
                                <FontAwesomeIcon size="lg" icon={faClipboard} />
                            </Button>
                            <p className="smaller">Files</p>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <Button variant="light" size="sm" className="mr-2 mb-1 poll w-100 text-dark" style={{ height: "60px", borderRadius: "10px" }}>
                                <FontAwesomeIcon size="lg" icon={faPoll} />
                            </Button>
                            <p className="smaller">Poll</p>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <Button variant="light" size="sm" className="mr-2 mb-1 quiz w-100 text-dark" style={{ height: "60px", borderRadius: "10px" }}>
                                <FontAwesomeIcon size="lg" icon={faTasks} />
                            </Button>
                            <p className="smaller">Quiz</p>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <Button variant="light" size="sm" className="mr-0 mb-1 assignment w-100 text-dark" style={{ height: "60px", borderRadius: "10px" }}>
                                <FontAwesomeIcon size="lg" icon={faFileAlt} />
                            </Button>
                            <p className="smaller">Assignment</p>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <Button variant="light" size="sm" className="mr-0 mb-1 assignment w-100 text-dark" style={{ height: "60px", borderRadius: "10px" }}>
                                <FontAwesomeIcon size="lg" icon={faChalkboard} />
                            </Button>
                            <p className="smaller">WhiteBoard</p>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <Button variant="light" size="sm" className="mr-0 mb-1 assignment w-100 text-dark" style={{ height: "60px", borderRadius: "10px" }}>
                                <FontAwesomeIcon size="lg" icon={faBook} />
                            </Button>
                            <p className="smaller">Gradebook</p>
                        </div>
                    </div>
                    <small className="d-block mt-2 mx-auto text-center">Learning Management Tools</small>
                </div>

                <div className="col-3">
                    <div className="row text-center rounded-lg pt-3">
                        <div className="col-md-12 col-sm-4">
                            <Button variant="light" size="sm" className="mr-2 mb-1 p-1 folder w-100" style={{ backgroundColor: "#DEDEDE", height: "60px", borderRadius: "10px" }}>
                                <FontAwesomeIcon size="lg" icon={faCog} />
                            </Button>
                            <p className="smaller">Room Settings</p>
                        </div>

                        <div className="col-md-12 col-sm-4">
                            <Button variant="light" size="sm" className="mr-2 mb-1 p-1 folder w-100" style={{ backgroundColor: "#DEDEDE", height: "60px", borderRadius: "10px" }}>
                                <FontAwesomeIcon size="lg" icon={faUsers} />
                            </Button>
                            <p className="smaller">People</p>
                        </div>

                        <div className="col-md-12 col-sm-4">
                            <Button variant="light" size="sm" className="mr-2 mb-1 p-1 folder w-100" style={{ backgroundColor: "#DEDEDE", height: "60px", borderRadius: "10px" }}>
                                <FontAwesomeIcon size="lg" icon={faCloud} />
                            </Button>
                            <p className="smaller">Cloud Drive</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row p-2">
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-3 col-sm-4">
                            <Button variant="light" size="sm" className="mr-0 my-2 assignment w-100 text-dark" style={{ backgroundColor: "#DEDEDE", height: "70px", borderRadius: "10px" }}>
                                <FontAwesomeIcon size="lg" icon={faChartLine} />
                            </Button>
                            <p className="small">Analytics</p>
                        </div>
                        <div className="col-md-3 col-sm-4">
                            <Button variant="light" size="sm" className="mr-0 my-2 assignment w-100 text-dark" style={{ backgroundColor: "#DEDEDE", height: "70px", borderRadius: "10px" }}>
                                <FontAwesomeIcon size="lg" icon={faUserPlus} />
                            </Button>
                            <p className="small">Invite</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

function DoctorProfile() {
    return (
        <div className="container">
            <h2>Dr. Jatin Ahuja</h2>
            <h5 className="small font-weight-bold">11 years of experience</h5>
            <h5 className="small font-weight-bold">MD, MBBS</h5>

            <h5>Specialties</h5>
            <p className="small">Internal Medicine, Gynaecology</p>
            <h5>Education</h5>
            <p className="small font-weight-bold mb-0">MBBS</p>
            <p>Government Medical College, Aurangabad</p>
            <p className="small font-weight-bold mb-0">MD (General Medicine)</p>
            <p>Swami Ramanand Teerth Rural Medical College, Aurangabad</p>
            <p className="small font-weight-bold mb-0">DNB (General Medicine)</p>
            <p>Maharaja Yahantrao Hospital & Medical College, Indore</p>
            <p className="small font-weight-bold">AFIH</p>
            <p className="small font-weight-bold">IDCC</p>
            <h5>About Dr. Vinutha Sasalatti</h5>
            <p className="small">Dr. Husam AbuZarad has been practicing in Hernando County since 2003. Dr. AbuZarad is the president and co-founder of the charity Crescent Community Clinic ( www.Crescentclinic.org ). The clinic has been providing quality healthcare and education, free of charge, for the indigent, uninsured and needy patients of Hernando County since 2008. He was nominated several times for the healthcare Heros and the first humanitarian awards for his volunteer charitable work at the clinic.
                                Dr. AbuZarad is married with three children. He enjoys charcoal sketching, photography, playing bag-pipes and swimming. Serving at the MEC at OHH as Vice Chief of Staff and Treasurer for the last 5 years. He is the co-author of a publication in clinical nephrology and while at Moffitt Cancer Center.</p>

        </div>
    )
}

function Map() {
    const AnyReactComponent = ({ text }) => (
        <div style={{
          color: 'white', 
          background: 'red',
          padding: '8px 12px',
          display: 'inline-flex',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '100%',
          transform: 'translate(-50%, -50%)',
          fontSize: '2em'
        }}>
          {text}
        </div>
      );
      const defaultProps = {
        center: {
          lat: 28.4117092,
          lng: 77.0619243
        },
        zoom: 17
      };
    return(
        <div style={{ height: '92vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyB9GowjznuRZx3ejQjrXYWp4eax2qk-mjk' }}
         defaultCenter={defaultProps.center}
         defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent
            lat={28.4117092}
            lng={77.0619243}
            text="&#10009;"
          />
          <AnyReactComponent
            lat={28.4126184}
            lng={77.0618418}
            text="&#10009;"
          />
        </GoogleMapReact>
      </div>
    )
}

function Specialties() {
    return (
        <>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="specialty1" />
                <label class="form-check-label" for="specialty1">
                    Gynecologist
                                    </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="specialty2" />
                <label class="form-check-label" for="specialty2">
                    General Physician
                                    </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="specialty3" />
                <label class="form-check-label" for="specialty3">
                    Orthopedician
                                    </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="specialty4" />
                <label class="form-check-label" for="specialty4">
                    Dietitian
                                    </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="specialty5" />
                <label class="form-check-label" for="specialty5">
                    Pediatrician
                                    </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="specialty6" />
                <label class="form-check-label" for="specialty6">
                    Dermatologist
                                    </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="specialty6" />
                <label class="form-check-label" for="specialty6">
                    Psychiatrist
                                    </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="specialty6" />
                <label class="form-check-label" for="specialty6">
                    Andrologist
                                    </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="specialty6" />
                <label class="form-check-label" for="specialty6">
                    Diabetologist
                                    </label>
            </div>
        </>
    )
}


const categoryoptions = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Cateogry</Popover.Title>
        <Popover.Content>
            And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
);

const Category = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Category</Button>
    </OverlayTrigger>
);

const Dates = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Dates</Button>
    </OverlayTrigger>
);

function DoctorList() {

    const handleChange = function (event) {
        console.log(event.target.value)
    }

    const history = useHistory();

    function handleClick() {
        history.push("/program");
    }

    const [doctorList, setDoctorList] = useState([]);
    const [loadingDoctorList, setLoadingDoctorList] = useState(true);

    useEffect(() => {

        let doctorListRef = firebase.firestore().collection('doctorList');
        doctorListRef.get()
            .then(snapshot => {
                let tempDoctorList = [];

                snapshot.forEach(doc => {
                    tempDoctorList.push({ facultyId: doc.id, ...doc.data() });
                });
                setDoctorList(tempDoctorList);
                setLoadingDoctorList(false);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });

    }, [])

    return (
        <>
            <div className="container-fluid bg-black vh-100">
                <div className="row h-7 bg-light border-bottom py-2">
                    <div className="col pt-2 small font-weight-bold">
                        <span className="mr-3 pb-1"><a href="" className="text-decoration-none">CONSULTATIONS</a></span>
                        <span className="mr-3">WELLNESS</span>
                        <span className="mr-3">LABS</span>
                        <span className="mr-3">HOSPITALS</span>
                    </div>
                    <div className="col h-100 text-center">
                        <img src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Figesia-logo-positive-sm.png?alt=media&token=c7b1d5b9-c7b1-4e6d-a771-3d88321d20f6"
                            height="100%"
                        />
                    </div>
                    <div className="col text-right pt-2 small font-weight-bold">
                        <span className="mr-3 ">हिंदी में पढ़े </span>
                        <span>LOGIN</span>
                    </div>
                </div>

                <div className="row h-93">
                    <div className="col-md-3 h-100 bg-light">
                        <div className="row h-100  p-4 border-right" style={{ overflow: "scroll" }}>
                            <div className="w-100" >
                                <div className="mb-4">
                                    <h5>Medicine type</h5>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="medicine1" />
                                        <label class="form-check-label" for="medicine1">
                                            Allopathy
                                    </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="medicine2" />
                                        <label class="form-check-label" for="medicine2">
                                            Homeopathy
                                    </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="medicine3" />
                                        <label class="form-check-label" for="medicine3">
                                            Ayurvedic
                                    </label>
                                    </div>
                                </div>
                                <h5>Specialties</h5>
                                <Specialties />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 h-100 bg-white  border-right">
                        <div className="row h-100 p-4" style={{ overflow: "scroll" }}>
                            <div className="p-4 bg-dark text-light rounded mb-5">
                                <h1 className="mb-3">उच्च गुणवत्ता वाली स्वास्थ्य सेवा और सभी के लिए कल्याण</h1>
                                <Button type="lg" className="mb-0">सेवा</Button>
                            </div>
                            <h2>Gynecologists</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas quis ultrices justo. Etiam eget risus finibus, ullamcorper est nec, semper libero.</p>

                            <div class="card mb-3">
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-2">
                                            <img className="rounded" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FScreenshot%202020-07-06%20at%209.38.37%20AM.png?alt=media&token=6828c0fc-2485-4e4d-91d9-70bf938c9c47" width="100%" />
                                        </div>
                                        <div className="col-6">
                                            <h5 class="card-title">Dr. Jatin Ahuja</h5>
                                            <h6 class="card-subtitle mb-2 text-muted">Gynaecologist</h6>
                                            <p class="card-text"><FontAwesomeIcon size="sm" icon={faMapMarkerAlt} /> New Delhi</p>
                                            <Button variant="link" size="sm" className="px-0 mr-3 mb-0">VIEW PROFILE</Button>
                                            <Button variant="primary" size="sm" className="mb-0">BOOK APPOINTMENT</Button>
                                        </div>
                                        <div className="col-4">
                                            <p className="small">11 years of experience</p>
                                            <p className="small">हिंदी, English, मराठी</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card mb-3">
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-2">
                                            <img className="rounded" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FScreenshot%202020-07-06%20at%209.38.37%20AM.png?alt=media&token=6828c0fc-2485-4e4d-91d9-70bf938c9c47" width="100%" />
                                        </div>
                                        <div className="col-6">
                                            <h5 class="card-title">Dr. Bharat Chopra</h5>
                                            <h6 class="card-subtitle mb-2 text-muted">Gynaecologist</h6>
                                            <p class="card-text"><FontAwesomeIcon size="sm" icon={faMapMarkerAlt} /> New Delhi</p>
                                            <Button variant="link" size="sm" className="px-0 mr-3 mb-0">VIEW PROFILE</Button>
                                            <Button variant="primary" size="sm" className="mb-0">BOOK APPOINTMENT</Button>
                                        </div>
                                        <div className="col-4">
                                            <p className="small">11 years of experience</p>
                                            <p className="small">हिंदी, English, मराठी</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card mb-3">
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-2">
                                            <img className="rounded" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FScreenshot%202020-07-06%20at%209.38.37%20AM.png?alt=media&token=6828c0fc-2485-4e4d-91d9-70bf938c9c47" width="100%" />
                                        </div>
                                        <div className="col-6">
                                            <h5 class="card-title">Dr. Deepak Verma</h5>
                                            <h6 class="card-subtitle mb-2 text-muted">Gynaecologist</h6>
                                            <p class="card-text"><FontAwesomeIcon size="sm" icon={faMapMarkerAlt} /> New Delhi</p>
                                            <Button variant="link" size="sm" className="px-0 mr-3 mb-0">VIEW PROFILE</Button>
                                            <Button variant="primary" size="sm" className="mb-0">BOOK APPOINTMENT</Button>
                                        </div>
                                        <div className="col-4">
                                            <p className="small">11 years of experience</p>
                                            <p className="small">हिंदी, English, मराठी</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-3 h-100 bg-light">
                        <div className="row h-100" style={{ overflow: "scroll" }}>
                            {/* <DoctorProfile /> */}
                            <Map />
                            {/* <RightFunctions /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DoctorList;