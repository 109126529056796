import React from 'react';
import { Card, Button, Image } from 'react-bootstrap'
import {
    Link
} from "react-router-dom";
import { StickyContainer, Sticky } from 'react-sticky';
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from './AcademyNavbar'

function Program() {
    return (
        <div>
            <AcademyNavbar />
            <div className="container-fluid pricing-bar py-3">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-6">
                            <p className="font-weight-bold mb-0">
                                <span className="d-block pt-2 small mb-0">2/6 FREE ARTICLES LEFT </span>
                            </p>
                        </div>
                        <div className="col-6 text-right">
                            <p className="font-weight-bold mb-0">
                                <span className="mr-2 d-none d-md-inline small">Why Subscribe?</span>
                                <Button variant="primary d-inline font-weight-bold mb-0" size="sm" style={{ marginTop: '-5px' }}>Subscribe</Button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container-fluid bg-black text-white pb-5 post-content pt-5">
                    <div className="container">
                        <div className="row mb-3">
                            <div className="col-md-8 ">
                                <h1 className="font-weight-light h2">How will the post-Covid world look like?</h1>
                                <p className="font-weight-light lead">The coronavirus pandemic has infected millions across the globe, upended markets and
                                    completely altered the business landscape as we know it. We explore top voices and experts to give you a holistic view of the future.</p>
                                <span class="badge badge-dark mr-2 mb-2 font-weight-normal p-2">LEADERSHIP</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 ">
                                <ol>
                                    <li>
                                        <p>
                                            <h3>The World Post-Coronavirus Crisis: Wharton Tarnopol Dean’s Lecture Series</h3>
                                            <p className="small muted">04-May-2020</p>
                                            <p>Notable Wharton Alumni talk about the need for more data and time to formulate a reliable
                                            vaccine as unrest grows among the public who are adopting an assumed-risk mentality for day
                                                    to day activities.</p>
                                            <blockquote class="blockquote">
                                                <p>Every business leader in some way is going to be a health care leader goingforward.</p>
                                                <footer class="blockquote-footer">Alex Gorsky &nbsp;<cite title="Source Title">Chairman and CEO of pharmaceuticals and medical devices company Johnson & Johnson</cite></footer>
                                            </blockquote>
                                            <div class="embed-responsive embed-responsive-16by9">
                                                <iframe class="embed-responsive-item"
                                                    src="https://www.youtube.com/embed/KuCa-ubAF-E"
                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen></iframe>
                                            </div>
                                            <blockquote class="blockquote">
                                                <p>If we don’t preserve the employers, it’s going to be very difficult to keep paying the employees.</p>
                                                <footer class="blockquote-footer">Marc Rowan &nbsp;<cite title="Source Title">Co-founder and Senior Managing Director of private equity firm Apollo Global Management</cite></footer>
                                            </blockquote>
                                            <blockquote class="blockquote">
                                                <p>Starting a business in a bad economy is a great idea because it lowers your cost of doing everything</p>
                                                <footer class="blockquote-footer">Andy Rachleff &nbsp;<cite title="Source Title">Founder of Benchmark Capital, a Silicon Valley venture capital firm</cite></footer>
                                            </blockquote>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <h3>How CEOs Can Lead Selflessly Through a Crisis</h3>
                                            <p>Examples of innovative applications of digital technologies in various sectors will be covered along
                                                with their strategic rationales and implementation challenges</p>
                                            <Card className="bg-dark link-card" style={{ maxWidth: '28rem' }}>
                                                <Card.Img variant="top" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe" />
                                                <Card.Body className="p-2">
                                                    <Card.Text>
                                                        <p className="url text-uppercase">WSJ.COM</p>
                                                        <p className="title">How Covid-19 is changing the language in emails</p>
                                                        <p className="meta-description">Work emails during the coronavirus pandemic must walk a fine line</p>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </p>
                                    </li>
                                    <li>
                                        <p><h3>Digital culture and mindsets</h3>A successful digital transformation requires strong and resolute leadership from the top complemented by a digital mindset that permeates the organization’s culture and mission</p>
                                    </li>
                                </ol>
                            </div>
                            <div className="col-md-3 offset-md-1">
                                <p className="small">Executive Programs you might be interested in</p>
                                <StickyContainer>
                                    <Sticky>
                                        {({
                                            style,

                                            // the following are also available but unused in this example
                                            isSticky,
                                            wasSticky,
                                            distanceFromTop,
                                            distanceFromBottom,
                                            calculatedHeight
                                        }) => (
                                                <Card style={style} className="bg-dark link-card">
                                                    <div className="meta-image" style={{ height: "120px", backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe)" }}></div>
                                                    <Card.Body className="p-2">
                                                        <Card.Text>
                                                            <p className="url text-uppercase">IGESIA.CO</p>
                                                            <p className="title">Innovation Management: Strategy and Execution</p>
                                                            <p className="meta-description">Leverage the innovative potential of your organization</p>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                    </Sticky>
                                </StickyContainer>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5  program-content-web" style={{ background: "#EFEFEF" }}>
                    <div className="container">
                        <div className="row pb-3">
                            <div className="col-md-4">
                                <h4>You might also be interested in</h4>
                            </div>
                        </div>
                        <div className="row pb-5">
                            <div className="col-md-3">
                                <Card className="bg-transparent link-card border-0">
                                    <div className="meta-image rounded mb-2" style={{ height: "180px", backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe)" }}></div>
                                    <Card.Body className="p-0">
                                        <Card.Text>
                                            <p className="url text-uppercase">IGESIA.CO</p>
                                            <p className="title">Innovation Management: Strategy and Execution</p>
                                            <p className="meta-description">Leverage the innovative potential of your organization</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-md-3">
                                <Card className="bg-transparent link-card border-0">
                                    <div className="meta-image rounded mb-2" style={{ height: "180px", backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe)" }}></div>
                                    <Card.Body className="p-0">
                                        <Card.Text>
                                            <p className="url text-uppercase">IGESIA.CO</p>
                                            <p className="title">Innovation Management: Strategy and Execution</p>
                                            <p className="meta-description">Leverage the innovative potential of your organization</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-md-3">
                                <Card className="bg-transparent link-card border-0">
                                    <div className="meta-image rounded mb-2" style={{ height: "180px", backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe)" }}></div>
                                    <Card.Body className="p-0">
                                        <Card.Text>
                                            <p className="url text-uppercase">IGESIA.CO</p>
                                            <p className="title">Innovation Management: Strategy and Execution</p>
                                            <p className="meta-description">Leverage the innovative potential of your organization</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-md-3">
                                <Card className="bg-transparent link-card border-0">
                                    <div className="meta-image rounded mb-2" style={{ height: "180px", backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe)" }}></div>
                                    <Card.Body className="p-0">
                                        <Card.Text>
                                            <p className="url text-uppercase">IGESIA.CO</p>
                                            <p className="title">Innovation Management: Strategy and Execution</p>
                                            <p className="meta-description">Leverage the innovative potential of your organization</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className="row pb-3 mx-1">
                            <div className="col border-top">
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col-md-4">
                                <h4>Explore other topics</h4>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Managing People
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Managing Self
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Leadership
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Strategy
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Finance
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Public Policy
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Industry 4.0
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Social Impact
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Communication
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Innovation
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Technology
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Entrepreneurship
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Marketing
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Career Planning
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Organizational Culture
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Decision Making
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Productivity
                                </Button>
                            </div>
                            <div className="col-md-2 col-6">
                                <Button
                                    className="px-2 py-3 mb-4 text-dark" style={{ lineHeight: "1.3", textAlign: "left", border: 0 }}
                                    variant="outline-light"
                                    >Work-Life Balance
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default Program;